import React from "react";
import RadioField from "../../../components/FinalForm/RadioField";
import InputField from "../../../components/FinalForm/InputField";
import {ImageGallery} from "../../../components/image-modal/ImageGallery";

const DriverClubForm = ({values,title="LEGENDY DRIVER'S CLUB", part=5, errors, submitFailed, disabled=false}) => {

    let driversClubGallery = [
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_3.jpg"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_6.jpg"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_7.jpg"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_10.png"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_11.png"},
    ]

    return (
        <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
            <div className="flex flex-row w-full mb-6">
                <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST {part}</span> - {title}</h4>
            </div>
           <div className={"mb-5"}> <ImageGallery images={driversClubGallery} showTitle={false} cols={5} showLabel={false}/></div>
            <div className={"w-full flex flex-col space-y-5"}>
                <div>Chcete si užít ještě víc? Připojte se k našemu exkluzivnímu LEGENDY Driver's Clubu!</div>
                <div>Během naší slavnosti máte jedinečnou příležitost využít všech výhod, které LEGENDY Driver's
                    Club nabízí.
                </div>
                <div>Tento VIP prostor bude k dispozici po celou dobu akce a nachází se na střechách Křižíkových
                    pavilonů - ideální místo
                    pro relaxaci, zastavení se a vychutnání si skvělého občerstvení.
                </div>

                <div>
                    <div>V rámci LEGENDY Driver's Clubu na vás čeká:</div>
                    <div>- 4x delikátní teplé menu, pečlivě připravené naším oblíbeným kuchařem Filipem Sajlerem
                    </div>
                    <div>- malý sladký bar pro zpestření</div>
                    <div>- pitný nealkoholický režim po celý den</div>
                </div>

                <div>LEGENDY Driver's Club je navržen pro maximální pohodlí, ať už jste majitel vozu nebo jeho
                    doprovod.
                    Očekává vás přátelská atmosféra a protřídní servis.
                </div>

                <div>
                    <div>Vstup do LEGENDY Driver's Clubu je k dispozici za 1.500,-Kč na tři dny (500,-Kč na den).
                    </div>
                    <div>Tento vstup je nepřenosný - zaručuje Vám tak exkluzivní přístup.</div>
                    <div>Kapacita tohoto VIP zázemí je omezena na 300 osob / den.</div>
                </div>

                <div>Pokud se bohužel nebudete moci na slavnost z jakéhokoliv důvodu následně dostavit, tento
                    poplatek je nevratný,
                    jelikož je určen na pokrytí nákladů spojených s provozem tohoto výjimečného prostoru.
                </div>

                <div>Vstup do LEGENDY Driver's Clubu není možné zakoupit na míste, tak neváhejte a zajistěte si své
                    místo nyní!
                </div>
                <div className={"w-full grid grid-cols-2 gap-2 mt-2"}>
                    <div className={"w-full"}>
                        <div className={"font-bold flex-col w-full"}>Přeji si zakoupit vstup do DRIVER'S Clubu a
                            využívat jej: <sup className={"text-legendyColor"}>*</sup></div>

                        <div className="w-full flex flex-row">
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.entry"} label={"ANO"}
                                            id={"driver-club-entry" + 0} value={1} disabled={disabled}
                                            group={"driverClub.entry"}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.entry === 1}
                                            isMandatory={true}/>
                            </div>
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.entry"} label={"NE"}
                                            id={"driver-club-entry" + 1} value={0} disabled={disabled}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.entry === 0}
                                            group={"driverClub.entry"} isMandatory={true}/>
                            </div>
                        </div>
                    </div>
                    {values?.user?.detail?.driverClub?.entry === 1 && <div className={"w-full"}>
                        <InputField label={"Jméno a příjmení"} name={"user.detail.driverClub.name"} isMandatory={true} disabled={disabled}/>
                    </div>}
                    {values?.user?.detail?.driverClub?.entry === 1 && <div className={"w-full"}>
                        <div className={"font-bold flex-col w-full"}>Přeji si zakoupit druhý vstup do DRIVER'S
                            Clubu pro doprovod: <sup className={"text-legendyColor"}>*</sup></div>

                        <div className="w-full flex flex-row">
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.secondEntry"} label={"ANO"} disabled={disabled}
                                            id={"driver-club-second-entry" + 0} value={1}
                                            group={"driverClub.secondEntry"}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.secondEntry === 1}
                                            isMandatory={true}/>
                            </div>
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.secondEntry"} label={"NE"} disabled={disabled}
                                            id={"driver-club-second-entry" + 1} value={0}
                                            group={"driverClub.secondEntry"}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.secondEntry === 0}
                                            isMandatory={true}/>
                            </div>
                        </div>
                    </div>}
                    {values?.user?.detail?.driverClub?.entry === 0 && <div className={"w-1/2"}>
                        <InputField label={"Důvod"} name={"detail.driverClub.noEntryWhyNot"} isMandatory={false} disabled={disabled}/>
                    </div>}
                    {values?.user?.detail?.driverClub?.secondEntry === 1 && <div className={"w-1/2"}>
                        <InputField label={"Jméno a příjmení"} name={"user.detail.driverClub.secondEntryName"} isMandatory={true} disabled={disabled}/>
                    </div>}
                </div>
            </div>
        </div>
    )

}
export default DriverClubForm;
