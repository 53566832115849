import React, {useEffect, useState} from "react";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {apiGetConfig, apiPutConfig} from "./Actions";
import {useNavigate} from "react-router-dom";
import {Form} from "react-final-form";
import {FiPlus, FiSave} from "react-icons/fi";
import {Button} from "../../components/My/Button";
import CogoToast from "cogo-toast";
import {DefaultLoader} from "../../components/DefaultLoader";
import RadioField from "../../components/FinalForm/RadioField";

const Config = () => {

    const navigate = useNavigate();

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let submit = null;

    useEffect(() => {
        setLoading(true);
        apiGetConfig((data) => {
            setData(data);
            setLoading(false);
        }, (err) => {
            navigate("/account/exhibit");
            setLoading(false);
        })
    }, [])

    const onSubmit = (values) => {
        setLoading(true);
        apiPutConfig((result) => {
            setData(result);
            setLoading(false);
            CogoToast.success("Uloženo.");
        }, (err) => {
            setLoading(false);
            CogoToast.error("Nepodařilo se uložit.");
        }, values);
    }

    const _renderButtons = () => {
        if (!data) return null;
        return <Button text={"Uložit"} icon={<FiSave/>} onClick={() => submit()}/>
    }

    const _renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám konfiguraci..."}/>
        return <>
            <div className="w-full flex justify-center bg-grey-50">
                <Form onSubmit={onSubmit} initialValues={data}
                      render={({handleSubmit, values, ...props}) => {
                          submit = handleSubmit;
                          return (
                              <div
                                  className={`grid grid-cols-2 gap-2 bg-white border border-grey-200 lg:p-8 sm: p-2 w-full sm:max-w-full mb-2 text-base`}>
                                      <div className={"form-element lg:mt-1"}>
                                          <div className="form-label font-bold">
                                              Registrace povoleny: <sup className={"text-legendyColor"}>*</sup>
                                          </div>

                                          <div className="lg:w-1/2 w-full flex flex-row">
                                              <div className={"flex flex-col"}>
                                                  <RadioField name={"registerEnable"} label={"ANO"}
                                                              id={"config-disablereg" + 0}
                                                              value={"1"}
                                                              defaultChecked={data && data.registerEnable === "1"}
                                                              group={"registerDis"} isMandatory={true}/>
                                              </div>
                                              <div className={"flex flex-col"}>
                                                  <RadioField name={"registerEnable"} label={"NE"}
                                                              id={"config-disablereg" + 1}
                                                              value={"0"}
                                                              defaultChecked={data && data.registerEnable === "0"}
                                                              group={"registerDis"} isMandatory={true}/>
                                              </div>
                                          </div>
                                      </div>

                                      <div className={"form-element lg:mt-1"}>
                                          <div className="form-label font-bold">
                                              Přihlášení pouze pro ředitele: <sup
                                              className={"text-legendyColor"}>*</sup>
                                          </div>

                                          <div className="lg:w-1/2 w-full flex flex-row">
                                              <div className={"flex flex-col"}>
                                                  <RadioField name={"onlyDirectorLogin"} label={"ANO"}
                                                              id={"config-onlyDirectorLogin" + 0}
                                                              value={"1"}
                                                              defaultChecked={data && data.onlyDirectorLogin === "1"}
                                                              group={"onlyDirectorLogin"} isMandatory={true}/>
                                              </div>
                                              <div className={"flex flex-col"}>
                                                  <RadioField name={"onlyDirectorLogin"} label={"NE"}
                                                              id={"config-onlyDirectorLogin" + 1}
                                                              value={"0"}
                                                              defaultChecked={data && data.onlyDirectorLogin === "0"}
                                                              group={"onlyDirectorLogin"} isMandatory={true}/>
                                              </div>
                                          </div>
                                      </div>
                              </div>
                          )
                      }}/>
            </div>

        </>
    }

    return <>
        <EnigooBreadcrumbs right={_renderButtons()}/>
        {_renderBody()}
    </>
}
export default Config
