import axios from "axios";
import Toast from "cogo-toast";

export const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "http://localhost:83" : "/",
    headers: {
        'Content-Type': 'application/json',
    },
});

const handleUnauthorized = () => {
    localStorage.removeItem("authorization-leg-enigoo");
    window.location.href = "/login";
    Toast.success("Nejste autorizován. Prosím přihlašte se znovu.");
};

instance.interceptors.response.use(undefined, function (error) {
    if (error?.response?.status === 401) {
        //handleUnauthorized();
    }
    return Promise.reject(error);
});

export const onErrorFunction = (error) => {
    if(error?.response?.data){
        return error.response.data
    }else{
        //return {type: null, message: null, data: null}

        return error
    }
}

