import RadioField from "../../../components/FinalForm/RadioField";
import {AiFillDislike, AiFillInfoCircle, AiFillLike, AiFillWarning} from "react-icons/all";
import InputField from "../../../components/FinalForm/InputField";
import SelectField from "../../../components/FinalForm/SelectField";
import CheckboxField from "../../../components/FinalForm/CheckboxField";
import React from "react";
import {SectionSelectField} from "../../Section/SectionSelectField";

const ExponatFormInfo = ({data,title = "EXPONÁT", part = 1,disabled = false}) => {
    const pavilonOptions = [
        /*{value: "tematicky", label: "Tématický pavilon"},
        {value: "supersport", label: "Pavilon Supersportů"},
        {value: "motocykly", label: "Pavilon Motocyklů"},*/
        {value: "venku", label: "Venkovní expozice"}
    ]
    return (
        <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
            <div className="flex flex-row w-full mb-6">
                <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST {part}</span> - {title}</h4>
            </div>

            <div className={"grid lg:grid-cols-5 grid-cols-2 gap-2 text-legendyColor text-lg"}>
                <RadioField disabled={disabled} name={"type"} label={"AUTOMOBIL"} id={"cc-t" + 0} value={0}
                            group={"type"} isMandatory={false} defaultChecked={data && data.type === 0}/>
                <RadioField disabled={disabled} name={"type"} label={"MOTOCYKL"} id={"cc-t" + 1} value={1}
                            group={"type"} isMandatory={false} defaultChecked={data && data.type === 1}/>
                <RadioField disabled={disabled} name={"type"} label={"KARAVAN"} id={"cc-t" + 2} value={2}
                            group={"type"} isMandatory={false} defaultChecked={data && data.type === 2}/>
                <RadioField disabled={disabled} name={"type"} label={"NÁKLADNÍ"} id={"cc-t" + 3} value={3}
                            group={"type"} isMandatory={false} defaultChecked={data && data.type === 3}/>
                <RadioField disabled={disabled} name={"type"} label={"JINÉ"} id={"cc-t" + 4} value={4}
                            group={"type"} isMandatory={false} defaultChecked={data && data.type === 4}/>
            </div>
            {!window.location.pathname.includes("/account/exhibit/add")?<div className={"flex flex-row text-lg pb-2"}>
                <div className={"mr-2 font-bold"}>STAV:</div>
                {data&& data.detail && data.detail.state === 0 && <><AiFillInfoCircle className={"mr-2"}
                                                                        style={{color: "mediumblue"}}
                                                                        size={25}/> Čeká na schválení</>}
                {data && data.detail&& data.detail.state === 1 && <><AiFillLike className={"mr-2"} style={{color: "green"}}
                                                                  size={25}/> Schváleno</>}
                {data && data.detail&& data.detail.state === 2 && <><AiFillWarning className={"mr-2"} style={{color: "orange"}}
                                                                     size={25}/> Je třeba doplnit údaje</>}
                {data && data.detail&& data.detail.state === 3 && <><AiFillDislike className={"mr-2"} style={{color: "red"}}
                                                                     size={25}/> Neschváleno</>}
            </div>:<></>}
            <div className={"grid lg:grid-cols-4 grid-cols-1 lg:gap-2 mb-2"}>
                <InputField disabled={disabled} label={"Název exponátu"} name={"nameExponat"} isMandatory={true}/>
                <InputField disabled={disabled} label={"Rok výroby"} name={"manufactureYear"} inputType={"number"} isMandatory={true} maxLength={4}/>
                <InputField disabled={disabled} label={"Motor (počet válců)"} name={"engine"} inputType={"number"} isMandatory={true} maxLength={10}/>
                <InputField disabled={disabled} label={"Objem motoru (ccm)"} name={"engineCapacity"} inputType={"number"}
                            isMandatory={true} maxLength={10}/>
                <InputField disabled={disabled} label={"Výkon motoru v HP (koně)"} name={"performanceHp"} inputType={"number"}
                            isMandatory={true}  maxLength={10}/>
                <InputField disabled={disabled} label={"Výkon motoru v kW"} name={"performanceKw"} inputType={"number"}
                            isMandatory={true}  maxLength={10}/>
                <InputField disabled={disabled} label={"Max. rychlost (km/h)"} name={"maxSpeed"} inputType={"number"}
                            isMandatory={true}  maxLength={10}/>
                <InputField disabled={disabled} label={"Spotřeba (l/100 km)"} name={"consumption"} inputType={"number"}
                            isMandatory={true}  maxLength={10}/>
                <InputField disabled={disabled} label={"Hodnota (Kč)"} name={"value"} inputType={"number"} isMandatory={false}  maxLength={20}/>
                <InputField disabled={disabled} label={"Laskavě vystavil (jméno)"} name={"exhibited"} isMandatory={false}  maxLength={100}/>
                <InputField disabled={disabled} label={"SPZ"} name={"spz"} isMandatory={true}  maxLength={10}/>
                <SectionSelectField disabled={disabled} label={"Sekce"} name={"section.id"} isMulti={false} placeHolder={"Vyberte sekci..."} isMandatory={true}/>
                <div className={"lg:col-span-4"}>
                    <InputField disabled={disabled} label={"Zajímavost o voze"} name={"note"} inputType={"textarea"} isMandatory={true}  maxLength={400}/>
                </div>
                <div className="form-element">
                    <div className={"form-label font-bold"}>
                        Vůz jede po ose: <sup className={"text-legendyColor"}>*</sup>
                    </div>

                    <div className="lg:w-1/2 w-full flex flex-row pt-2">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.byAxis"} label={"ANO"} id={"axis-d" + 0} value={1}
                                        group={"detail.byAxis"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.byAxis === 1}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.byAxis"} label={"NE"} id={"axis-d" + 1} value={0}
                                        group={"detail.byAxis"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.byAxis === 0}/>
                        </div>
                    </div>
                </div>

                <div className="form-element">
                    <div className={"form-label font-bold"}>
                        Vůz jede na vleku a chci zaparkovat vlek: <sup className={"text-legendyColor"}>*</sup>
                    </div>

                    <div className="lg:w-1/2 w-full flex flex-row pt-2">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.byTow"} label={"ANO"} id={"byTow" + 0} value={1}
                                        group={"detail.byTow"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.byTow === 1}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.byTow"} label={"NE"} id={"byTow" + 1} value={0}
                                        group={"detail.byTow"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.byTow === 0}/>
                        </div>
                    </div>
                </div>

                <div className="form-element">
                    <div className={"form-label font-bold"}>
                        Vůz může být venku: <sup className={"text-legendyColor"}>*</sup>
                    </div>

                    <div className="w-full flex flex-row pt-2">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.canOut"} label={"ANO"} id={"canOut" + 0} value={1}
                                        group={"detail.canOut"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.canOut === 1}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.canOut"} label={"S ohrazením"} id={"canOut" + 1} value={2}
                                        group={"detail.canOut"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.canOut === 2}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.canOut"} label={"NE"} id={"canOut" + 2} value={0}
                                        group={"detail.canOut"} isMandatory={true}
                                        defaultChecked={data&& data.detail && data.detail.canOut === 0}/>
                        </div>
                    </div>
                </div>

                <div className="form-element">
                    <div className={"form-label font-bold"}>
                        Má vůz povinné pojištění: <sup className={"text-legendyColor"}>*</sup>
                    </div>

                    <div className="lg:w-1/2 w-full flex flex-row pt-2">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.insuranceRequire"} label={"ANO"} id={"insurance-req" + 0}
                                        value={1}
                                        group={"detail.insuranceRequire"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.insuranceRequire === 1}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.insuranceRequire"} label={"NE"} id={"insurance-req" + 1}
                                        value={0}
                                        group={"detail.insuranceRequire"} isMandatory={true}
                                        defaultChecked={data&& data.detail && data.detail.insuranceRequire === 0}/>
                        </div>
                    </div>
                </div>

                <div className="form-element">
                    <div className={"form-label font-bold"}>
                        Má vůz havarijní pojištění: <sup className={"text-legendyColor"}>*</sup>
                    </div>

                    <div className="lg:w-1/2 w-full flex flex-row pt-2">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.insuranceHav"} label={"ANO"} id={"insurance-hav" + 0}
                                        value={1}
                                        group={"detail.insuranceHav"} isMandatory={true}
                                        defaultChecked={data&& data.detail && data.detail.insuranceHav === 1}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"detail.insuranceHav"} label={"NE"} id={"insurance-hav" + 1} value={0}
                                        group={"detail.insuranceHav"} isMandatory={true}
                                        defaultChecked={data && data.detail&& data.detail.insuranceHav === 0}/>
                        </div>
                    </div>
                </div>
                <InputField disabled={disabled} label={"Pojišťovna, u které je vůz pojištěn"} name={"detail.insurance"}
                            isMandatory={false}/>
                <InputField disabled={disabled} name={"detail.refueling"} label={"Tankuji palivo u"}
                            isMandatory={false} maxLength={50}/>

                <SelectField disabled={disabled} name={"detail.pavilon"} options={pavilonOptions} label={"Umístění do pavilonu"}
                             isMandatory={true}/>
                <div className={"form-element w-full p-1"}>
                    <div className={"form-label font-bold"}>Parkování pro denní vůz / doprovodné vozidlo:</div>
                    <div className="w-full flex flex-col pt-2 pb-2">
                        <div className={"flex flex-row ml-1"}>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} name={"detail.parking"} label={"Pátek"} value={"P"}
                                               viewError={false} isMandatory={false}/>
                            </div>
                            <div className={"flex-row mr-3"}>
                                <CheckboxField disabled={disabled} name={"detail.parking"} label={"Sobota"} value={"S"}
                                               viewError={false} isMandatory={false}/>
                            </div>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} name={"detail.parking"} label={"Neděle"} value={"N"}
                                               viewError={false} isMandatory={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ExponatFormInfo;
