import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetConfig = (callback, error) => {
    instance.get("/api/v1/config")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutConfig = (callback, error, values) => {
    instance.put("/api/v1/config", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
