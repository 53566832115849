import React from "react";
import store from "./store";
import {Provider} from "react-redux";
import Token from "./Token";
import CenteredForm from "./layouts/centered-form";
import Layout from '../src/layouts/centered'

function App() {
    let isActive = true;

    if (!isActive) {
        {
            return <Layout>
                <div className={"flex flex-col w-full max-w-xl"}>
                    <div className={"flex items-center justify-center pb-6"}>
                        <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                    </div>
                    <CenteredForm subtitle="">
                        <div className={"text-center"}>
                            <h4>REGISTRACE NA LEGENDY 2025 NEBYLA JEŠTĚ SPUŠTĚNA</h4>
                        </div>
                    </CenteredForm>
                </div>
            </Layout>
        }
    }

    return (
        <Provider store={store}>
            <Token/>
        </Provider>
    );
}

export default App;
