import {ImageGallery} from "../../../components/image-modal/ImageGallery";
import FileField from "../../../components/FinalForm/FileField";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/My/Button";
import PhotoModal from "./PhotoModal";
import {FiPlus} from "react-icons/fi";
import {apiPutUploadPhoto} from "../Actions";

const ImagesFormDetail = ({
                              data,
                              setData,
                              disabled = false,
                              type = "update",
                              updateImage = apiPutUploadPhoto
                          }) => {
    let[showModal, setShowModal] = useState(false);
    let images = [
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/car_photo_1.jpg", title: "Interiér"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/car_photo_2.jpg", title: "Z boku"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/car_photo_3.jpg", title: "Ze předu"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/car_photo_4.jpg", title: "Ze shora"},
    ]

    useEffect(() => {

    }, []);

    return <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
        <div className="flex flex-row w-full mb-6">
            <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST 2</span> -
                FOTOGRAFIE</h4>
        </div>
        {type === "update" ? <>
            <div className={"mr-2 font-bold"}>JIŽ NAHRANÉ FOTOGRAFIE:</div>
            <ImageGallery images={data?data.image:[]}/></> : <></>}
        {disabled ? <></> : <>
            <div className={"mr-2 font-bold"}>PŘIDÁNÍ NOVÉ FOTOGRAFIE:</div>
            <div>(Jedna fotografie o maximální velikosti 5 MB)</div>
            <div className={"mt-4 mb-2"}>Vzorové fotografie:</div>
            <ImageGallery images={images} showTitle={true}/>
            <Button onClick={()=>setShowModal(true)} text={"Přidat fotografii"} icon={<FiPlus/>}/>
            <PhotoModal apiUploadImage={updateImage} setOpen={setShowModal} open={showModal} addPhoto={(photo)=>{
                if(setData) {
                    let images = data.image;
                    images.push({src: photo.data, title: photo.name})
                    setData({...data,image:images});
                    console.log(data);
                }
            }}/>
        </>}
    </div>
}
export default ImagesFormDetail;
