import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {Form} from "react-final-form";
import EnigooBreadcrumbs from "../../../components/breadcrumbs/EnigooBreadcrumbs";
import { FiSave, RiExchangeBoxLine} from "react-icons/all";
import {Button} from "../../../components/My/Button";
import {apiGetExponatById, apiPostExponatNewAction, apiPutExponat} from "../Actions";
import {shallowEqual, useSelector} from "react-redux";
import ActionDropDown from "../../../components/FinalForm/ActionDropDown";
import UserInfoForm from "./UserInfoForm";
import ExponatFormInfo from "./ExponatFormInfo";
import ImagesFormDetail from "./ImagesFormDetail";
import ActionDetailForm from "./ActionDetailForm";
import {FiPlus} from "react-icons/fi";
import {ChangeExponatStateModal} from "../../AdminExponat/ChangeExponatStateModal";
import {ExponatAddToSectionModal} from "../../AdminExponat/ExponatAddToSectionModal";
import DriverClubForm from "./DriverClubForm";
import ConfirmRegisterExponat from "../../../components/EnigooModals/ConfirmRegisterExponat";

const ExponatDetail = () => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    let [confirmRegister, setConfirmRegister] = useState(false);

    let [showModal, setShowModal] = useState(false);
    let [savingState, setSavingState] = useState(false);
    let [assignToSectionOpen, setAssignToSectionOpen] = useState(false);
    const navigate = useNavigate();
    let submit = null;
    const {id} = useParams();
    let submitError = false;

    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )
    const {activeAction} = useSelector(
        state => (state.activeAction),
        shallowEqual
    )

    let {userState} = useSelector(
        state => ({
            userState: state.user,
        }),
        shallowEqual
    );

    useEffect(() => {
        getData();
    }, [])

    const sendToApi = (values) => {
        values.photo=data.image;
        apiPostExponatNewAction({actionId: activeAction.id, id: id, data: values}, (data) => {
            Toast.success("Exponát byl registrován do nového ročníku.");
            setSaving(false);
            navigate("/account/exhibit/detail/" + data.id);
        }, (err) => {
            Toast.error("Exponát se nepodařilo zaregistrovat do nového ročníku.")
            setSaving(false);
        })
    }

    const onSubmit = (values) => {
        setSaving(true);

        if (id) {
            values.photo=data.image;
            if (window.location.pathname.includes("/account/exhibit/action") || window.location.pathname.includes("/account/exhibit/add")) {
                setConfirmRegister(true);
            } else {
                apiPutExponat(id, {data: values}, (data) => {
                    let image = [];
                    data.image && data.image.map((item, index) => {
                        image.push({src: item.data, title: item.name})
                    })
                    data.image = image;
                    setData(data);
                    Toast.success("Exponát byl upraven.");
                    setSaving(false);
                }, (error) => {
                    Toast.error("Exponát se nepodařilo upravit.");
                    setSaving(false);
                })
            }
        }
    }

    const getData = () => {
        setLoading(true);
        const onSuccess = (data) => {
            let image = [];
            data.image.map((item, index) => {
                image.push({src: item.data, title: item.name})
            })
            data.image = image;
            if (window.location.pathname.includes("/account/exhibit/action/")) {
                data.detail = {};
            }
            if(data && !data.detail){
                Toast.error("Požadovaný exponát neexistuje.");
                navigate("/account/exhibit")
            }
            let newData = data;
            if(newData?.user?.phone){
                let formattedPhone = newData.user.phone.replace(/\D/g, "");

                if (formattedPhone.length === 9) {
                    newData.user.phone = `+420 ${formattedPhone.slice(0, 3)} ${formattedPhone.slice(3, 6)} ${formattedPhone.slice(6)}`;
                }

                if (formattedPhone.length === 12 && formattedPhone.startsWith("420")) {
                    newData.user.phone = `+420 ${formattedPhone.slice(3, 6)} ${formattedPhone.slice(6, 9)} ${formattedPhone.slice(9)}`;
                }
            }
            setData(newData);
            setLoading(false);
        };
        const onError = (error) => {
            Toast.error("Nepodařilo se načíst data exponátu.");
            setLoading(false);
        }
        apiGetExponatById(id, onSuccess, onError)
    }

    const _renderBody = (values, handleSubmit) => {
        if (loading) return <DefaultLoader text={"Načítám data exponátu..."}/>;
        if (data) return (
            <>
            {(window.location.pathname.includes("/account/exhibit/action") || window.location.pathname.includes("/account/exhibit/add")) && <ConfirmRegisterExponat visible={confirmRegister} setVisible={setConfirmRegister} onAccept={()=>sendToApi(values)}/>}
                {submitError && data && data.detail  && data.detail.state !== 1 ?
                    <div
                        className={`flex flex-col bg-red-100 border border-l-4 border-red-500 rounded text-red-500 p-8 pb-8 w-full mb-2 text-base`}>
                        Vyplňte všechna povinná pole.
                    </div> : <></>
                }
                <div className={"flex items-center justify-center pt-4 pb-6"}>
                    <img src={"https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/registrace_banner_1.png"}
                         className={"h-56"} alt={"Legendy.cz"}/>
                </div>
                <ExponatFormInfo data={data} disabled={data && data.detail && data.detail.state === 1}/>
                <div className={"flex items-center justify-center pt-4 pb-6"}>
                    <img src={"https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/registrace_banner_2.png"}
                         className={"h-56"} alt={"Legendy.cz"}/>
                </div>
                <ImagesFormDetail data={data} setData={setData} disabled={data && data.detail  && data.detail.state === 1}/>
                <div className={"flex items-center justify-center pt-4 pb-6"}>
                    <img src={"https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/registrace_banner_3.png"}
                         className={"h-56"} alt={"Legendy.cz"}/>
                </div>
                <UserInfoForm disabled={data  && data.detail && data.detail.state === 1}/>
                <div className={"flex items-center justify-center pt-4 pb-6"}>
                    <img src={"https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/registrace_banner_4.png"}
                         className={"h-56"} alt={"Legendy.cz"}/>
                </div>
                <ActionDetailForm values={values} disabled={data  && data.detail && data.detail.state === 1}/>
                <div className={"flex items-center justify-center pt-4 pb-6"}>
                    <img src={"https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/registrace_banner_1.png"}
                         className={"h-56"} alt={"Legendy.cz"}/>
                </div>
                <DriverClubForm values={values} disabled={data  && data.detail && data.detail.state === 1}/>
                {data  && data.detail && data.detail.state === 1 ? <></> : <div
                    className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
                    <Button icon={<FiSave/>} loading={saving} text={"Uložit"} onClick={handleSubmit}/>
                    {submitError ?
                        <span className={"text-red-500"}>Vyplňte všechna povinná pole.</span> : <></>}
                </div>}

            </>

        )
        return <></>;
    }


    const _renderButtons = (handleSubmit) => {
        let showSectionButton = (userState.user.user_type === 1 || userState.user.user_type === 2) && !window.location.pathname.includes("/account/exhibit");
        let showStateButton = !window.location.pathname.includes("/account/exhibit");
        let showSave = !(data && data.detail && data.detail.state);
        let cols = 4;
        if (!showSectionButton) cols--;
        if (!showStateButton) cols--;
        if (!showSave) cols--;

        return <div className={`grid grid-cols-${cols} gap-2`}>
            {showSectionButton ? <Button text={"Přiřadit sekci"} onClick={() => setAssignToSectionOpen(true)}
                                         icon={<FiPlus/>} extraStyle={"mr-2"}/> : <></>}
            {showStateButton ? <Button icon={<RiExchangeBoxLine/>} text={"Změnit stav"}
                                       onClick={() => setShowModal(true)}/> : <></>}
            {showSave ? <Button icon={<FiSave/>} loading={saving} text={"Uložit"} onClick={handleSubmit}/> : <></>}
            <ActionDropDown/>
        </div>
    }

    return (
        <Form onSubmit={onSubmit} initialValues={data}
              render={({handleSubmit, values, hasSubmitErrors, hasValidationErrors}) => {
                  submitError = hasSubmitErrors || hasValidationErrors;
                  submit = handleSubmit;
                  return (<>
                          <ChangeExponatStateModal setOpen={setShowModal} open={showModal} setData={setData}
                                                   saving={savingState} setSaving={setSavingState}/>
                          <ExponatAddToSectionModal selectedIds={[id]} open={assignToSectionOpen}
                                                    setOpen={setAssignToSectionOpen}/>
                          <EnigooBreadcrumbs right={_renderButtons(handleSubmit)}/>
                          {_renderBody(values, handleSubmit)}
                      </>
                  )
              }}
        />
    )


}
export default ExponatDetail;
