import React, {useMemo} from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import {useSelector} from "react-redux";
import CheckboxField from "./CheckboxField";
import {FiArrowUp} from "react-icons/all";

const TelephoneField = ({...props}) => {


    const isInvalid = (_props) => {
        if (_props.meta.error && _props.meta.touched) return true;
        return false;
    }


    return (
        <Field {...props} validate={value => {

            if (props.isMandatory && (value === undefined || value === null)) return true
            if (value === undefined || value === null) return false

            if (!value.state) return false
            if (!props.isMandatory && value.value === "") return false
            let item = phones[value.state.countryCode.toUpperCase()];
            if (!item) {
                if (value.value.length > 5) {
                    return false
                } else {
                    return "Telefonní číslo nemá správný formát"
                }
            } else if (value.value.match(item)) {
                return false;
            } else {
                return "Telefonní číslo nemá správný formát"
            }

            return false;
        }} render={_props => {
            return (
                <div className={"form-element w-full p-1"}>
                    {props.label && <div className={"form-label font-bold"}>{props.label}: {props.isMandatory &&
                        <sup className={"text-legendyColor"}>*</sup>}</div>}
                    <PhoneInput
                        {..._props.input}
                        disabled={props.disabled}
                        country={"cz"}
                        buttonClass={props.buttonClass}
                        //preferredCountries={["cz", "sk", "de", "pl", "fr", "at"]}
                        preferredCountries={["cz"]}
                        onlyCountries={["cz"]}
                        placeholder={"+420 xxx xxx xxx"}
                        specialLabel={false}
                        value={_props.input.value}
                        onChange={(value, state) => {
                            if (value === "") {
                                _props.input.onChange(null)
                            } else {
                                _props.input.onChange(value)
                            }

                        }}
                    />
                    {_props.meta.error && _props.meta.touched &&
                        <div className={"text-sm pt-1"}>
                            <div className={"flex flew-row text-red-500"}>
                                <FiArrowUp/> {_props.meta.error === true ? "Povinné pole" : _props.meta.error}
                            </div>
                        </div>
                    }
                </div>

            )
        }}/>
    )
}
CheckboxField.defaultProps = {
    valueAs: 'string',
    isMandatory: false
}

TelephoneField.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
    isMandatory: PropTypes.bool.isRequired,
    valueAs: PropTypes.oneOf(["string", 'json']),
}

export default TelephoneField


const phones = {
    'CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
};
