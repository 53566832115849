import React, {useRef, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {FiCheck} from "react-icons/fi";
import {Form} from "react-final-form";
import {Button} from "../My/Button";
import Portal from "../portal";
import CogoToast from "cogo-toast";
import CheckboxField from "../FinalForm/CheckboxField";

const ConfirmRegisterExponat = ({onAccept, visible, setVisible}) => {

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )

    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)

    const send = (values) => {
        if(!values.accept){
            CogoToast.error("Musíte souhlasit s podmínkami.")
        }else{
            setVisible(false);
            onAccept();
        }
    }

    if (visible) {
        return (
            <Portal selector="#portal">
                <div className="modal-backdrop fade-in bg-blue-500"></div>
                <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                     data-background={background}>
                    <div className="relative min-w-sm w-3/4 mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                        <div className="modal-content">
                            <Form onSubmit={send} render={({handleSubmit}) => (
                                <>
                                    <div className={"m-5 text-lg text-justify flex flex-col space-y-5"}>
                                        <div className={"font-bold"}>Souhlas s podmínkami registrace</div>
                                        <div>
                                            Dokončením této registrace potvrzujete svůj souhlas s dodržením všech postupů a podmínek registrace.
                                            <br/>Dále souhlasíte s&nbsp;
                                            <a className={"underline text-blue-600"} target={"_blank"} href={"https://legendy.cz/provozni-a-navstevni-rad/"}>návštěvním a provozním řádem slavnosti Legendy</a>
                                            &nbsp;a podmínkami účasti.
                                            <br/>Dbejte prosíme na to, že nedodržení těchto podmínek může mít vliv na Vaši účast.
                                        </div>
                                        <div>
                                            Pokud se majitel se svým zaregistrovaným a&nbsp;schváleným vozem neúčastní akce, a&nbsp;to&nbsp;z&nbsp;jakéhokoliv důvodu,
                                            má slavnost Legendy právo požadovat úhradu organizačního poplatku ve&nbsp;výši 1.500,-Kč a&nbsp;to&nbsp;bez&nbsp;protestu.
                                            Tento poplatek bude uhrazen bezhotovostním způsobem na&nbsp;účet slavnosti Legendy.
                                            Platební údaje a pokyny k&nbsp;úhradě budou zaslány majitelům vozů, kteří se neuzúčastní, do&nbsp;7&nbsp;dnů od&nbsp;konání akce.
                                        </div>
                                        <div>
                                            Děkujeme, že ctíte a dodržujete pravidla a těšíme se na Vás!
                                        </div>
                                        <div className={"w-full text-center font-bold"}>
                                            <CheckboxField name={"accept"} isMandatory={false} label={"Souhlasím s podmínkami registrace vozu"}/>
                                        </div>
                                    </div>

                                    <div className="modal-footer space-x-2">
                                        <Button onClick={handleSubmit}
                                                text={"Potvrdit"} icon={<FiCheck/>}/>
                                    </div>
                                </>
                            )}/>
                        </div>
                    </div>
                </div>
            </Portal>
        )
    } else {
        return <></>
    }
}

export default ConfirmRegisterExponat;
