import React, {useRef, useState} from "react";
import Portal from "../portal";
import {Form} from "react-final-form";
import {Button} from "../My/Button";
import {FiSend} from "react-icons/fi";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {CLOSE_WELCOME_MODAL, SET_ACCEPT_TERMS} from "../../reducers/user";

const WelcomeModal = ({}) => {

    const {user} = useSelector((state) => state);

    const dispatch = useDispatch();
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )

    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)

    const send = (values) => {
        dispatch({type:CLOSE_WELCOME_MODAL, payload:true})
    }

    if(user.welcomeModal) {


        return (
            <Portal selector="#portal">
                <div className="modal-backdrop fade-in bg-blue-500"></div>
                <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                     data-background={background}>
                    <div className="relative min-w-sm w-1/2 mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                        <div className="modal-content">
                            <Form onSubmit={send} render={({handleSubmit}) => (
                                <div className={"w-full"}>
                                    <div className={"m-5 text-lg flex flex-col text-center content-center "}>
                                        <span className={"font-bold underline"}>Vítejte v&nbsp;našem registračním systému!</span>
                                        <span className={"my-3"}>Slavnost a výstava se koná od&nbsp;30.5.2025&nbsp;do&nbsp;1.6.2025 na&nbsp;Výstavišti Praha&nbsp;-&nbsp;Holešovice</span>
                                    </div>
                                    <div className={"m-5 text-lg flex flex-col"}>
                                        <span className={"font-bold"}>Co Vás čeká bude-li Váš vůz schválen komisí:</span>
                                        <ul className={"ml-5 list-disc"}>
                                            <li>Stanete se součástí jedinečné akce, která je největší v ČR!</li>
                                            <li>Obdržíte celodenní vstup* pro sebe a Váš doprovod na slavnost LEGENDY na tři dny (*hodnota 4.000,-Kč).</li>
                                            <li>Získáte možnost zakoupení až 2 ks vstupů do LEGENDY Driver's Club.</li>
                                            <li>Váš vůz se může stát HVĚŽDOU slavnosti LEGENDY 2025!</li>
                                        </ul>
                                        <span className={"text-center center-center mt-5"}>Těšíme se na viděnou!</span>
                                    </div>

                                    <div className="modal-footer space-x-2">
                                        <Button onClick={handleSubmit}
                                                text={"Pokračovat"} icon={<FiSend/>}/>
                                    </div>
                                </div>
                            )}/>
                        </div>
                    </div>
                </div>
            </Portal>
        )
    }else{
        return <></>
    }
}
export default WelcomeModal;
