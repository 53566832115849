import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {apiGetAuthRegisterToken, apiGetRegisterToken} from "./Actions";
import {instance} from "../../config/axios-cnf";
import Registration from "./Registration";
import {DefaultLoader} from "../../components/DefaultLoader";
import {Button} from "../../components/My/Button";
import {FiSkipBack} from "react-icons/all";
const RegisterApp = () => {

    let [registerToken, setRegisterToken] = useState(null);
    let [registerDisabled, setRegisterDisabled] = useState(false);

    let token = localStorage.getItem("reg-authorization-leg-enigoo");
    const navigate = useNavigate();

    useEffect(() => {
        if(token){
            instance.defaults.headers.common["reg-authorization"] = token;
            apiGetAuthRegisterToken((data)=>{
                setRegisterToken(token);
                setRegisterDisabled(false);
            },(error)=>{
                localStorage.removeItem("reg-authorization-leg-enigoo");
                apiGetRegisterToken((data)=>{
                    setRegisterToken(data.token);
                    localStorage.setItem("reg-authorization-leg-enigoo",data.token);
                    instance.defaults.headers.common["reg-authorization"] = data.token;
                    setRegisterDisabled(false);
                },(error)=>{
                    setRegisterToken(null);
                    if(error.type === "REGISTER_DISABLED"){
                        setRegisterDisabled(true);
                    }else{
                        setRegisterDisabled(false);
                        navigate("/");
                    }
                })
            })
        }else{
            apiGetRegisterToken((data)=>{
                setRegisterToken(data.token);
                localStorage.setItem("reg-authorization-leg-enigoo",data.token);
                instance.defaults.headers.common["reg-authorization"] = data.token;
                setRegisterDisabled(false);
            },(error)=>{
                setRegisterToken(null);
                if(error.type === "REGISTER_DISABLED"){
                    setRegisterDisabled(true);
                }else{
                    setRegisterDisabled(false);
                    navigate("/");
                }
            })
        }
    }, []);

    if(registerDisabled){
        return (
            <div className="w-full flex justify-center bg-grey-50">
                {/*<div className={"lg:fixed lg:top-0 lg:left-0 p-2"}> <Button link={"/"} text={"ZPĚT na úvodní stránku"} icon={<BiArrowBack/>}/></div>*/}
                <div className={"flex flex-col w-full max-w-5xl"}>

                    <div className={"flex items-center justify-center pt-4 pb-6"}>
                        <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                    </div>
                    <h2 className={"text-center font-bold mb-2"}>REGISTRACE</h2>
                    <div className={"w-full flex justify-center"}>
                        <h2>Registrace na Slavnost Legendy ještě není spuštěna.</h2>
                    </div>
                    <Button text={"ZPĚT"} icon={<FiSkipBack/>} onClick={()=>navigate("/")}/>
                </div>
            </div>
        )
    }

    if(registerToken) return <Registration/>
    else {
        return <DefaultLoader text={"Načítám data..."}/>
    }

}
export default RegisterApp;
