import React from "react";
import {Routes, Route, Redirect, Navigate} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Exponat from "./pages/Exponat/Exponat";
import ExponatForm from "./pages/Exponat/ExponatForm";
import User from "./pages/User/User";
import UserForm from "./pages/User/UserForm";
import Section from "./pages/Section/Section";
import SectionForm from "./pages/Section/SectionForm";
import AdminExponat from "./pages/AdminExponat/AdminExponat";
import AdminExponatDetail from "./pages/AdminExponat/AdminExponatDetail";
import ExponatDetail from "./pages/Exponat/Form/ExponatDetail";
import Analysis from "./pages/Analysis/Analysis";
import NewExponatForm from "./pages/Exponat/Form/NewExponatForm";
import Config from "./pages/Config/Config";

const Routing = () => {

    return (
        <Routes>
            {/*<Route path={"/:account/settings"} element={<Dashboard/>}/>*/}
            <Route path={"/account/exhibit"} element={<Exponat/>}/>
            <Route path={"/account/exhibit/add"} element={<NewExponatForm/>}/>
            <Route path={"/account/exhibit/detail/:id"} element={<ExponatDetail/>}/>
            <Route path={"/account/exhibit/action/:id"} element={<ExponatDetail/>}/>
            <Route path={"/settings/section"} element={<Section/>}/>
            <Route path={"/settings/section/add"} element={<SectionForm/>}/>
            <Route path={"/settings/section/detail/:id"} element={<SectionForm/>}/>
            <Route path={"/settings/user"} element={<User/>}/>
            <Route path={"/settings/user/add"} element={<UserForm/>}/>
            <Route path={"/settings/user/detail/:id"} element={<UserForm/>}/>
            <Route path={"/settings/exhibit"} element={<AdminExponat/>}/>
            <Route path={"/settings/exhibit/detail/:id"} element={<AdminExponatDetail/>}/>
            <Route path={"/settings/analysis"} element={<Analysis/>}/>
                <Route path={"/settings/config"} element={<Config/>}/>
            <Route path={"*"} element={<Navigate to={"/account/exhibit"}/>}/>
        </Routes>
    );
};
export default Routing;
