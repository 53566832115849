import InputField from "../../components/FinalForm/InputField";
import React, {useEffect, useState} from "react";
import CheckboxField from "../../components/FinalForm/CheckboxField";
import RadioField from "../../components/FinalForm/RadioField";
import SelectField from "../../components/FinalForm/SelectField";
import {apiGetFood} from "./Actions";
import CogoToast from "cogo-toast";
import {ImageGallery} from "../../components/image-modal/ImageGallery";


export const ActionDetail = ({title, values, errors, submitFailed, type = "register"}) => {
    let [loadingFood, setLoadingFood] = useState(false);
    let [eatOptions, setEatOptions] = useState(null);

    const sizes = [
        {value: "S", label: "S"},
        {value: "M", label: "M"},
        {value: "L", label: "L"},
        {value: "XL", label: "XL"},
        {value: "2XL", label: "2XL"},
        {value: "3XL", label: "3XL"}
    ]

    const drinkOptions = [
        {value: "voda", label: "Voda"},
        {value: "limo", label: "Limo"},
        {value: "kava", label: "Káva"},
        {value: "nealko", label: "Nealko pivo"},
        {value: "pivo", label: "Pivo"},
        {value: "vino", label: "Víno"}
    ]

    /*const eatOptions = [
        {
            day: "friday",
            label: "Pátek",
            options: [
                {
                    name: "dinner",
                    label: "Večeře",
                    values: [
                        {value: "jidlo1", label: "Jidlo 1"},
                        {value: "jidlo2", label: "Jidlo 2"}
                    ]
                }]
        }, {
            day: "saturday",
            label: "Sobota",
            options: [
                {
                    name: "lunch",
                    label: "Oběd",
                    values: [
                        {value: "jidlo1", label: "Jidlo 1"},
                        {value: "jidlo2", label: "Jidlo 2"}
                    ]
                },
                {
                    name: "dinner",
                    label: "Večeře",
                    values: [
                        {value: "jidlo1", label: "Jidlo 1"},
                        {value: "jidlo2", label: "Jidlo 2"}
                    ]
                }
            ]
        }, {
            day: "sunday",
            label: "Neděle",
            options: [
                {
                    name: "lunch",
                    label: "Oběd",
                    values: [
                        {value: "jidlo1", label: "Jidlo 1"},
                        {value: "jidlo2", label: "Jidlo 2"}
                    ]
                }
            ]
        }
    ]*/

    const pavilonOptions = [
        /*{value: "tematicky", label: "Tématický pavilon"},
        {value: "supersport", label: "Pavilon Supersportů"},
        {value: "motocykly", label: "Pavilon Motocyklů"},*/
        {value: "venku", label: "Venkovní expozice"}
    ]

    let driversClubGallery = [
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_3.jpg"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_6.jpg"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_7.jpg"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_10.png"},
        {src: "https://enigoo-legends.s3.eu-central-1.amazonaws.com/fe_photos/terrace_club_11.png"},
    ]

    return (
        <div
            className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 w-full ${type === "register" ? 'lg:max-w-5xl' : ''} mb-2 text-base`}>
            <div className="flex flex-col w-full mb-1">
                {title}
            </div>

            <div className="flex flex-col w-full mb-6">
                <div className="lg:text-lg sm:text-sm">Prosíme o pečlivé vyplnění doplňujících informací.
                    Tyto informace jsou důvěrné
                    a slouží pouze k správnému zajištění kapacity
                    jednotlivých položek. Děkujeme za vstřícnost.
                </div>
            </div>
            {type === "register" &&
                <>
                    <h6 className={"font-bold mb-8"}>Majitel exponátu</h6>
                </>}

            <div className="form flex flex-wrap w-full">
                {type === "register" &&
                    <div className={"w-full"}>
                        {/*<div className={"grid lg:grid-cols-2 grid-cols-1 lg:gap-2 place-content-start"}>
                            <div className={"form-element lg:mt-1"}>
                                <div className="form-label font-bold">
                                    Velikost trička - řidič: <sup className={"text-legendyColor"}>*</sup>
                                </div>
                                <div className="lg:w-1/2 w-full flex flex-row">
                                    <div className={"flex flex-col"}>
                                        <RadioField name={"detail.driver.sex"} label={"Žena"} id={"s-d" + 0}
                                                    value={"Žena"}
                                                    group={"driver.sex"} isMandatory={true}/>
                                    </div>
                                    <div className={"flex flex-col"}>
                                        <RadioField name={"detail.driver.sex"} label={"Muž"} id={"s-d" + 1}
                                                    value={"Muž"}
                                                    group={"driver.sex"} isMandatory={true}/>
                                    </div>
                                </div>
                            </div>

                            <SelectField name={"detail.driver.sizeShirt"} options={sizes} label={"Velikost trička"}
                                         isMandatory={true}/>

                        </div>*/}
                        <div className={"grid lg:grid-cols-2 grid-cols-1 flex justify-center"}>
                            <div className={"form-element lg:mt-1"}>
                                <div className="form-label font-bold">
                                    Jsem aktivním členem klubu: <sup className={"text-legendyColor"}>*</sup>
                                </div>

                                <div className="lg:w-1/2 w-full flex flex-row">
                                    <div className={"flex flex-col"}>
                                        <RadioField name={"detail.driver.inClub"} label={"Ano"} id={"inc-d" + 0}
                                                    value={1}
                                                    group={"driver.inClub"} isMandatory={true}/>
                                    </div>
                                    <div className={"flex flex-col"}>
                                        <RadioField name={"detail.driver.inClub"} label={"Ne"} id={"inc-d" + 1}
                                                    value={0}
                                                    group={"driver.inClub"} isMandatory={true}/>
                                    </div>
                                </div>
                            </div>

                            <InputField name={"detail.driver.club"} label={"Název klubu"}
                                        isMandatory={values?.detail?.driver?.inClub === 1} maxLength={300}/>
                        </div>
                        <div className="w-full">
                            <InputField name={"detail.driver.onLegend"}
                                        label={"Již jsem se účastnil slavnosti LEGENDY (Počet)"}
                                        inputType={"number"} isMandatory={true} maxLength={11}/>
                        </div>
                        <div className={"w-full flex lg:flex-row flex-col items-stretch"}>
                            <div className={"flex flex-col mb-1 lg:w-1/2 w-full"}>
                                <div className={"ml-1 w-full font-bold"}>Budu na LEGENDÁCH:</div>
                                <div className={"ml-2 flex-col text-sm"}>(Aktivně se budu účastnit celý den)</div>
                            </div>
                            <div className="ml-1 lg:w-1/2 w-full flex flex-col">
                                <div className={"flex flex-row"}>
                                    <div className={"flex-col mr-3"}>
                                        <CheckboxField isMandatory={false} name={"detail.driver.onLegendDays"}
                                                       label={"Pátek"} value={"P"}/>
                                    </div>
                                    <div className={"flex-col mr-3"}>
                                        <CheckboxField isMandatory={false} name={"detail.driver.onLegendDays"}
                                                       label={"Sobota"}
                                                       value={"S"}/>
                                    </div>
                                    <div className={"flex-col mr-3"}>
                                        <CheckboxField isMandatory={false} name={"detail.driver.onLegendDays"}
                                                       label={"Neděle"}
                                                       value={"N"}/>
                                    </div>
                                </div>
                                <div className={"flex"}>
                                    {submitFailed && errors.detail?.driver?.onLegendDays ?
                                        <div className={"w-full text-sm text-red-500"}>Vyberte alespoň jednu
                                            možnost</div> : ""}
                                </div>
                            </div>
                        </div>
                        {/*<div className={"w-full flex lg:flex-row flex-col items-stretch mt-2"}>
                            <div className={"flex flex-col mb-1 lg:w-1/2 w-full"}>
                                <div className={"ml-1 w-full font-bold"}>Chci jít na večerní koncert:</div>
                                <div className={"ml-2 flex-col text-sm"}>(Blues, Jazz, Classic, Pop)</div>
                            </div>
                            <div className="ml-1 lg:w-1/2 w-full flex flex-col">
                                <div className={"flex flex-row"}>
                                    <div className={"flex-col mr-3"}>
                                        <CheckboxField isMandatory={false} name={"detail.driver.concert"}
                                                       label={"Pátek"}
                                                       value={"P"}/>
                                    </div>
                                    <div className={"flex-col mr-3"}>
                                        <CheckboxField isMandatory={false} name={"detail.driver.concert"}
                                                       label={"Sobota"}
                                                       value={"S"}/>
                                    </div>
                                </div>
                                <div className={"flex"}>
                                    {submitFailed && errors.detail?.driver?.concert ?
                                        <div className={"w-full text-sm text-red-500"}>Vyberte alespoň jednu
                                            možnost</div> : ""}
                                </div>
                            </div>
                        </div>
                        <div className={"ml-1 lg:w-full font-bold mt-2"}>Budu pít v Drivers Clubu:</div>
                        <div className={"grid lg:grid-cols-7 grid-cols-3 gap-2 ml-1 my-1 pb-8"}>
                            {drinkOptions.map((item, index) => (
                                <div className={"flex-col mr-3"} key={index}>
                                    <CheckboxField isMandatory={false} name={"detail.driver.drink"}
                                                   label={item.label}
                                                   value={item.value} key={index}/>
                                </div>
                            ))}
                        </div>

                        <div className={"ml-1 lg:w-1/3 font-bold mt-2"}>Budu jíst v Drivers Clubu:</div>
                        {loadingFood && <div>Načítám jídelníček...</div>}
                        {!loadingFood && eatOptions!=null && <div className={"grid lg:grid-cols-3 grid-cols-1 gap-3 ml-1 my-1 pb-8"}>
                            {eatOptions.map((item, index) => (
                                <div className={"flex flex-col"} key={index}>
                                    <div className={"font-light"}>{item.label}</div>
                                    {item.options.map((value, j) => (
                                        <div className={"flex flex-row"} key={j}>
                                            <SelectField name={`detail.driver.eat.${item.day}.${value.name}`}
                                                         options={value.values} label={value.label}/>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>}

                        <div className={"w-full flex lg:flex-row flex-col"}>
                            <div className="lg:w-1/2 w-full font-bold">Chci se zúčastnit Soutěže krásy - GRAND PRIX
                                LEGENDY:
                            </div>
                            <div className="lg:w-1/2 w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.miss"} label={"ANO"} id={"miss-d" + 0} value={1}
                                                group={"driver.miss"} isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.miss"} label={"NE"} id={"miss-d" + 1} value={0}
                                                group={"driver.miss"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>

                         <div className={"w-full flex lg:flex-row flex-col"}>
                            <div className="lg:w-1/2 w-full font-bold">Chci se zúčastnit spanilé vyjížďky Prahou:</div>
                            <div className="lg:w-1/2 w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.racePrague"} label={"ANO"} id={"race-d" + 0}
                                                value={1}
                                                group={"driver.racePrague"} isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.racePrague"} label={"NE"} id={"race-d" + 1}
                                                value={0}
                                                group={"driver.racePrague"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>*/}

                        <div className={"w-full flex lg:flex-row flex-col"}>
                            <div className="lg:w-1/2 w-full font-bold">Budu aktivně prezentovat svůj vůz</div>
                            <div className="lg:w-1/2 w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.present"} label={"ANO"} id={"present-d" + 0}
                                                value={1}
                                                group={"driver.present"} isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.present"} label={"NE"} id={"present-d" + 1}
                                                value={0}
                                                group={"driver.present"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>

                        <div className={"w-full flex lg:flex-row flex-col"}>
                            <div className="lg:w-1/2 w-full font-bold">Využiji druhý vstup: <sup
                                className={"text-legendyColor"}>*</sup></div>
                            <div className="lg:w-1/2 w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.secondDriver.is"} label={"ANO"} id={"sd-sd" + 0} value={1}
                                                group={"secondDriver.is"} isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.secondDriver.is"} label={"NE"} id={"sd-sd" + 1} value={0}
                                                group={"secondDriver.is"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>

                        {values?.detail?.secondDriver?.is === 1 &&
                            <>
                                <InputField label={"Jméno a příjmení doprovodu"} name={"detail.secondDriver.name"}
                                            isMandatory={true}/>
                                <div className={"form-element w-full p-1"}>
                                    <div className={"form-label font-bold"}>Dny, kdy dorazí doprovod:</div>
                                    <div className="w-full flex flex-col pt-2 pb-2">
                                        <div className={"flex flex-row ml-1"}>
                                            <div className={"flex-col mr-3"}>
                                                <CheckboxField name={"detail.parking"}
                                                               label={"Pátek"} value={"P"}
                                                               viewError={false} isMandatory={false}/>
                                            </div>
                                            <div className={"flex-row mr-3"}>
                                                <CheckboxField name={"detail.parking"}
                                                               label={"Sobota"} value={"S"}
                                                               viewError={false} isMandatory={false}/>
                                            </div>
                                            <div className={"flex-col mr-3"}>
                                                <CheckboxField name={"detail.parking"}
                                                               label={"Neděle"} value={"N"}
                                                               viewError={false} isMandatory={false}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className={"grid lg:grid-cols-2 grid-cols-1 lg:gap-2 place-content-start"}>
                                    <div className={"form-element lg:mt-1"}>
                                        <div className="form-label font-bold">
                                            Velikost trička - spolujezdec: *
                                        </div>
                                        <div className="lg:w-1/2 w-full flex flex-row">
                                            <div className={"flex flex-col"}>
                                                <RadioField name={"detail.secondDriver.sex"} label={"Žena"}
                                                            id={"s-sd" + 0}
                                                            value={"Žena"}
                                                            group={"secondDriver.sex"} isMandatory={true}/>
                                            </div>
                                            <div className={"flex flex-col"}>
                                                <RadioField name={"detail.secondDriver.sex"} label={"Muž"}
                                                            id={"s-sd" + 1}
                                                            value={"Muž"}
                                                            group={"secondDriver.sex"} isMandatory={true}/>
                                            </div>
                                        </div>
                                    </div>

                                    <SelectField name={"detail.secondDriver.sizeShirt"} options={sizes}
                                                 label={"Velikost trička"}
                                                 isMandatory={true}/>

                                </div>
                                <div className={"ml-1 lg:w-1/3 font-bold"}>Spolujezdec bude pít v Drivers Clubu: <sup
                                    className={"text-legendyColor"}>*</sup></div>
                                <div className={"grid lg:grid-cols-7 grid-cols-3 gap-2 ml-1 my-1 pb-8"}>
                                    {drinkOptions.map((item, index) => (
                                        <div className={"flex-col mr-3"} key={index}>
                                            <CheckboxField isMandatory={false} name={"detail.secondDriver.drink"}
                                                           label={item.label} value={item.value} key={index}/>
                                        </div>
                                    ))}
                                </div>

                                <div className={"ml-1 lg:w-1/3 font-bold"}>Spolujezdec bude jíst v Drivers Clubu: <sup
                                    className={"text-legendyColor"}>*</sup></div>
                                {loadingFood?<div>Načítám možnosti jídla...</div>:<div className={"grid lg:grid-cols-3 grid-cols-1 gap-3 ml-1 my-1 pb-8"}>
                                    {eatOptions.map((item, index) => (
                                        <div className={"flex flex-col"} key={index}>
                                            <div className={"font-light"}>{item.label}</div>
                                            {item.options.map((value, j) => (
                                                <div className={"flex flex-row"} key={j}>
                                                    <SelectField
                                                        name={`detail.secondDriver.eat.${item.day}.${value.name}`}
                                                        options={value.values} label={value.label}/>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>}
                                */}
                            </>
                        }


                        <div className="w-full"><h6 className={"font-bold mb-2 mt-4"}>Speciální žádost</h6></div>
                        <div className={"w-full flex flex-col ml-1"}>
                            <div className="w-full flex-col">
                                <div className={"font-bold flex-col"}>Chci se účastnit, ale chci exponátem večer odjet
                                </div>
                                <div className={"flex-col text-sm"}>Pravidla udělení výjimky:<br/>
                                    Přijedu v pátek, sobotu, neděli, vždy 1 hodinu před otevřením pro veřejnost a
                                    umístím vůz na stanovené místo.<br/>
                                    Vůz bude vybaven označením a popiskou.
                                </div>
                                <div className={"flex-col text-sm"}>
                                    (Tato speciální výjimka bude udělena pouze maximálně 25 účastníkům. Děkujeme za
                                    pochopení.)
                                </div>
                            </div>
                            <div className="w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.leave"} label={"ANO"} id={"leave-d" + 0} value={1}
                                                group={"driver.leave"} isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driver.leave"} label={"NE"} id={"leave-d" + 1} value={0}
                                                group={"driver.leave"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="lg:w-1/3 w-full"><h6 className={"font-bold mb-2 mt-4"}>Doplňující informace</h6>
                </div>

                <div className="lg:w-full w-full">
                    <InputField name={"detail.exponat.insurance"} label={"Exponát mám pojištěný u (povinné ručení)"}
                                isMandatory={false} maxLength={40}/>
                </div>
                {type === "register" &&
                    <>
                        <div className="lg:w-full w-full">
                            <InputField name={"detail.driver.refueling"} label={"Tankuji palivo u"}
                                        isMandatory={false} maxLength={50}/>
                        </div>
                        <div className="lg:w-full w-full">
                            <InputField name={"detail.driver.otherAction"}
                                        label={"Aktivně se účastním i těchto dalších akcí"}
                                        isMandatory={false} maxLength={400}/>
                        </div>
                        <div className="lg:w-full w-full">
                            <InputField name={"detail.driver.recomend"}
                                        label={"Jaké jiné akce bych organizátorovi doporučil"}
                                        isMandatory={false} inputType={"textarea"} maxLength={400}/>
                        </div>
                        <div className="lg:w-full w-full">
                            <InputField name={"detail.driver.recomendToAction"}
                                        label={"Doporučil bych organizátorovi akce (připomínky)"}
                                        isMandatory={false} inputType={"textarea"} maxLength={400}/>
                        </div>
                    </>}


                <div className="w-full"><h6 className={"font-bold mb-1"}>Doplňující informace - exponát</h6>
                </div>

                <div className="lg:w-full w-full">
                    <InputField name={"spz"} label={"SPZ"}
                                note={"(napište ve formě 1A1 0000, případně AA 11 22)"} isMandatory={true}
                                maxLength={10} minLength={4}/>
                </div>

                <div className="lg:w-1/2 w-full pt-2 font-bold">Povinné ručení: <sup
                    className={"text-legendyColor"}>*</sup></div>
                <div className="lg:w-1/2 w-full flex flex-row pt-2">
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.insuranceRequire"} label={"ANO"} id={"ins-d" + 0}
                                    value={1}
                                    group={"exponat.insuranceRequire"} isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.insuranceRequire"} label={"NE"} id={"ins-d" + 1}
                                    value={0}
                                    group={"exponat.insuranceRequire"} isMandatory={true}/>
                    </div>
                </div>

                <div className="lg:w-1/2 w-full font-bold pt-2">Havarijní pojistka: <sup
                    className={"text-legendyColor"}>*</sup></div>
                <div className="lg:w-1/2 w-full flex flex-row pt-2">
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.insuranceHav"} label={"ANO"} id={"ins-hav-d" + 0}
                                    value={1}
                                    group={"exponat.insuranceHav"} isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.insuranceHav"} label={"NE"} id={"ins-hav-d" + 1}
                                    value={0}
                                    group={"exponat.insuranceHav"} isMandatory={true}/>
                    </div>
                </div>

                <div className="lg:w-1/2 w-full font-bold pt-2">Vůz jede po ose: <sup
                    className={"text-legendyColor"}>*</sup></div>
                <div className="lg:w-1/2 w-full flex flex-row pt-2">
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.byAxis"} label={"ANO"} id={"axis-d" + 0} value={1}
                                    group={"exponat.byAxis"} isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.byAxis"} label={"NE"} id={"axis-d" + 1} value={0}
                                    group={"exponat.byAxis"} isMandatory={true}/>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full font-bold pt-2">Vůz jede na vleku a chci zaparkovat vlek: <sup
                    className={"text-legendyColor"}>*</sup></div>
                <div className="lg:w-1/2 w-full flex flex-row pt-2">
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.byTow"} label={"ANO"} id={"tow-d" + 0} value={1}
                                    group={"exponat.byTow"} isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.byTow"} label={"NE"} id={"tow-d" + 1} value={0}
                                    group={"exponat.byTow"} isMandatory={true}/>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full font-bold pt-1 pb-1">
                    <div className={"flex flex-col mb-1"}>
                        <div className={"font-bold"}>Chtěl bych parkování pro denní vůz / doprovodné vozidlo:</div>
                        <div className={"fond-bold"}>(není garantováno)</div>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full flex flex-col pt-2 pb-2">
                    <div className={"flex flex-row ml-1"}>
                        <div className={"flex-col mr-3"}>
                            <CheckboxField isMandatory={false} name={"detail.exponat.parking"} label={"Pátek"}
                                           value={"P"}
                                           viewError={false}/>
                        </div>
                        <div className={"flex-col mr-3"}>
                            <CheckboxField isMandatory={false} name={"detail.exponat.parking"} label={"Sobota"}
                                           value={"S"}
                                           viewError={false}/>
                        </div>
                        <div className={"flex-col mr-3"}>
                            <CheckboxField isMandatory={false} name={"detail.exponat.parking"} label={"Neděle"}
                                           value={"N"}
                                           viewError={false}/>
                        </div>
                    </div>
                    <div className={"flex"}>
                        {submitFailed && errors.detail?.exponat?.parking ?
                            <div className={"w-full text-sm text-red-500"}>Vyberte alespoň jednu
                                možnost</div> : ""}
                    </div>

                </div>
                <div className="lg:w-1/2 w-full font-bold pt-3">Exponát může být součástí venkovní
                    expozice: <sup
                        className={"text-legendyColor"}>*</sup></div>
                <div className="lg:w-1/2 w-full flex flex-row pt-3">
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.canOut"} label={"ANO"} id={"canout-d" + 0} value={1}
                                    group={"exponat.canOut"} isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.canOut"} label={"Ano, ale pouze s ohrazením"}
                                    id={"canout-d" + 1}
                                    value={2} group={"exponat.canOut"} isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField name={"detail.exponat.canOut"} label={"NE"} id={"canout-d" + 2} value={0}
                                    group={"exponat.canOut"} isMandatory={true}/>
                    </div>
                </div>

                <div className={"lg:w-1/2 w-full"}>
                    <SelectField name={"detail.exponat.pavilon"} options={pavilonOptions}
                                 label={"Umístění exponátu"} isMandatory={true}/>
                </div>
                <div className={"w-full flex flex-col space-y-5"}>
                    <div className={"mt-5 font-bold mb-2 text-lg"}>Driver's club</div>
                    <div className={"mb-5"}> <ImageGallery images={driversClubGallery} showTitle={false} cols={5} showLabel={false}/></div>
                    <div>Chcete si užít ještě víc? Připojte se k našemu exkluzivnímu LEGENDY Driver's Clubu!</div>
                    <div>Během naší slavnosti máte jedinečnou příležitost využít všech výhod, které LEGENDY Driver's
                        Club nabízí.
                    </div>
                    <div>Tento VIP prostor bude k dispozici po celou dobu akce a nachází se na střechách Křižíkových
                        pavilonů - ideální místo
                        pro relaxaci, zastavení se a vychutnání si skvělého občerstvení.
                    </div>

                    <div>
                        <div>V rámci LEGENDY Driver's Clubu na vás čeká:</div>
                        <div>- 4x delikátní teplé menu, pečlivě připravené naším oblíbeným kuchařem Filipem Sajlerem
                        </div>
                        <div>- malý sladký bar pro zpestření</div>
                        <div>- pitný nealkoholický režim po celý den</div>
                    </div>

                    <div>LEGENDY Driver's Club je navržen pro maximální pohodlí, ať už jste majitel vozu nebo jeho
                        doprovod.
                        Očekává vás přátelská atmosféra a protřídní servis.
                    </div>

                    <div>
                        <div>Vstup do LEGENDY Driver's Clubu je k dispozici za 1.500,-Kč na tři dny (500,-Kč na den).
                        </div>
                        <div>Tento vstup je nepřenosný - zaručuje Vám tak exkluzivní přístup.</div>
                        <div>Kapacita tohoto VIP zázemí je omezena na 300 osob / den.</div>
                    </div>

                    <div>Pokud se bohužel nebudete moci na slavnost z jakéhokoliv důvodu následně dostavit, tento
                        poplatek je nevratný,
                        jelikož je určen na pokrytí nákladů spojených s provozem tohoto výjimečného prostoru.
                    </div>

                    <div>Vstup do LEGENDY Driver's Clubu není možné zakoupit na míste, tak neváhejte a zajistěte si své
                        místo nyní!
                    </div>


                    <div className={"w-full grid grid-cols-2 gap-2 mt-2"}>
                        <div className={"w-full"}>
                            <div className={"font-bold flex-col w-full"}>Přeji si zakoupit vstup do DRIVER'S Clubu a
                                využívat jej: <sup className={"text-legendyColor"}>*</sup></div>

                            <div className="w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driverClub.entry"} label={"ANO"}
                                                id={"driver-club-entry" + 0} value={1}
                                                group={"detail.driverClub.entry"}
                                                defaultChecked={values && values?.user?.detail?.driverClub === 1}
                                                isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driverClub.entry"} label={"NE"}
                                                id={"driver-club-entry" + 1} value={0}
                                                defaultChecked={values && values?.user?.detail?.driverClub === 0}
                                                group={"detail.driverClub.entry"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>
                        {values?.detail?.driverClub?.entry === 1 && <div className={"w-full"}>
                            <InputField label={"Jméno a příjmení"} name={"detail.driverClub.name"} isMandatory={true}/>
                        </div>}
                        {values?.detail?.driverClub?.entry === 1 && <div className={"w-full"}>
                            <div className={"font-bold flex-col w-full"}>Přeji si zakoupit druhý vstup do DRIVER'S
                                Clubu pro doprovod: <sup className={"text-legendyColor"}>*</sup></div>

                            <div className="w-full flex flex-row">
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driverClub.secondEntry"} label={"ANO"}
                                                id={"driver-club-second-entry" + 0} value={1}
                                                group={"detail.driverClub.secondEntry"}
                                                defaultChecked={values && values?.user?.detail?.driverClub === 1}
                                                isMandatory={true}/>
                                </div>
                                <div className={"flex-col"}>
                                    <RadioField name={"detail.driverClub.secondEntry"} label={"NE"}
                                                id={"driver-club-second-entry" + 1} value={0}
                                                defaultChecked={values && values?.user?.detail?.driverClub === 0}
                                                group={"detail.driverClub.secondEntry"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>}
                        {values?.detail?.driverClub?.entry === 0 && <div className={"w-1/2"}>
                            <InputField label={"Důvod"} name={"detail.driverClub.noEntryWhyNot"} isMandatory={false}/>
                        </div>}
                        {values?.detail?.driverClub?.secondEntry === 1 && <div className={"w-full"}>
                            <InputField label={"Jméno a příjmení"} name={"user.detail.driverClub.secondEntryName"} isMandatory={true}/>
                        </div>}

                    </div>
                </div>
            </div>
            {type === "register" && <div className={"flex flex-col items-center justify-center pt-4 pb-6"}>
                <img src={"/assets/logo-legendy.png"} className={"h-32 mt-10"} alt={"Legendy.cz"}/>
                <h3 className={"text-legendyColor mt-10"}>DĚKUJEME</h3>
            </div>}
        </div>
    )
}



